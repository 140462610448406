import { useEffect, useState } from "react";
import MobileHeader from "../MobileHeader"
import { Link } from "react-router-dom"
import NavigationMenu from "../NavigationMenu";

const Header = () => {

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`${isSticky ? 'fixed-header' : ''} header-mobile-style`}>
      <MobileHeader />
      <div className="main-header header-three text-white">
        <div className="header-upper">
          <div className="container clearfix">
            <div className="header-inner d-flex align-items-center">
              <div className="logo-outer">
                <div className="logo">
                  {isSticky ? <Link to="/#"><img src={`/images/logo/logo-blue.png`} alt="Logo" title="Logo"/></Link> : <Link to="/#"><img src={`/images/logo/logo-white.png`} alt="Logo" title="Logo"/></Link>}
                </div>
              </div>
              <div className="nav-outer clearfix d-flex align-items-center">
                <nav className="main-menu navbar-expand-lg">
                  <NavigationMenu />
                </nav>
                {/* <MeetBtn /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
export default Header