import i18n  from "i18next";
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"



const NavigationMenu = () => {
  const { t } = useTranslation();


  const changeLanguage = (lang) => {
    if (lang === "eng") {
      i18n.changeLanguage("eng");
    }
    else {
      i18n.changeLanguage("sr");
    }
  }

  return (
    <>
      <div className="navbar-collapse collapse clearfix">
        <ul className="navigation clearfix mb-0 mt-3">
          <li className="dropdown"><Link to="/">{t('home')}</Link></li>
          <li className="dropdown"><Link to="/about-us">{t('aboutus')}</Link></li>
          <li className="dropdown"><Link to="/product">{t('product')}</Link></li>
          <li className="dropdown"><Link to="/service">{t('service')}</Link></li>
          <li className="dropdown"><Link to="/careers">{t('careers')}</Link></li>
          <li className="dropdown"><Link to="/contact#">{t('contact')}</Link></li>
          <li className="ml-30">
          <img src={`/images/language/english.png`} className={`pe-2 ${i18n.language === 'eng' ? 'opacity-50' : ''}`} alt="" onClick={() => changeLanguage('eng')} role="button"></img>
          <img src={`/images/language/serbian.png`} className={i18n.language === 'sr' ? 'opacity-50' : ''} alt="" onClick={() => changeLanguage('ser')} role="button"></img>
          </li>  
        </ul>
      </div>
    </>
  )
}
export default NavigationMenu