import { Link } from "react-router-dom"
import Banner from "../components/Banner"
import { useTranslation } from "react-i18next"
import workWithUs from "../data/workWithUsData.json"
import positions from "../data/openedPositionsData.json"
// import JobApplyForm from "../components/JobApplyForm"

const Careers = () => {
  const { t } = useTranslation();

  return (
    <>
    <Banner pageTitle={t('careers')} bannerClass="contact-us" />
    <div className="careers-page">
      {/* <Banner title="careers" /> */}
      <section className="about-area-five pt-50 pb-70 rpt-0 rpb-65 rel z-1">
        <div className="container">
          <div className="row align-items-center gap-100">
            <div className="col-lg-6 animation-left">
              <div className="about-content rmt-50 rel z-1 wow fadeInLeft delay-0-2s">
                <div className="section-title-careers mb-60 rmb-40 text-center">
                  <span className="sub-title mb-15">{t('be_part_of_team')}</span>
                  <h2 dangerouslySetInnerHTML={{ __html: t('why_work') }}></h2>
                </div>
                <div className="row gap-40">
                  {workWithUs.map((item, key) => (
                    <div className="col-6">
                      <div className="service-item style-three text-center text-md-start">
                        <div className="icon">
                          <img src={`images/careers/${item.icon}.png`} alt=""></img>
                        </div>
                        <h4 className="d-none d-md-block">{t(item.title)}</h4>
                        <h6 className="d-block d-md-none font-bold two-lines">{t(item.title)}</h6>
                        <p>{t(item.text)}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-2 order-md-1 animation-right">
              <div className="mt-50 rmt-30 rel z-1 wow fadeInRight delay-0-2s">
                <img src="/images/careers/careers-img.png" className="w-100 my-auto" alt="About" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-50 pb-70 rpy-50 rel z-1">
        <div className="container">
          <div className="section-title mb-60 rmb-40 text-center">
            <span className="sub-title mb-15">{t('join_the_team')}</span>
            <h2>{t('opened_positions')}</h2>
          </div>
          <div className="row justify-content-center">
            {positions.map((item, key) => (
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="service-item-six-careers wow fadeInUp delay-0-2s text-center">
                  <div className="icon">
                    <i className={item.icon} />
                  </div>
                  <h4>{item.title}</h4>
                  <Link to={`/job-apply/${item.id}`}>
                    {/* <p className={`theme-btn style-two`}> {t('apply')}<i className="fas fa-long-arrow-right" /></p> */}
                    <p className={`theme-btn style-two`}> {t('apply')}</p>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
    </>
  )
}
export default Careers


























// import { Fade } from "react-reveal"
// import Banner from "../components/Banner"
// import CVForm from "../components/ContactForm/CVForm"
// import Jobs from "../components/Jobs"
// import JobsData from "../data/JobsData.json"
// import { useTranslation } from "react-i18next"


// const Careers = ({ aboutClass, textColor, counter = true }) => {
//   const cariers = [
//     {
//       "id": "1",
//       "title": "flexible_time_title",
//       "text": "flexible_time_text",
//       "delay": 200
//     },
//     {
//       "id": "2",
//       "title": "events_title",
//       "text": "events_text",
//       "delay": 400
//     },
//     {
//       "id": "3",
//       "title": "open_source_title",
//       "text": "open_source_text",
//       "delay": 400
//     },
//     {
//       "id": "4",
//       "title": "sustainable_pace_title",
//       "text": "sustainable_pace_text",
//       "delay": 600
//     }
//   ]

//   const { t } = useTranslation();

//   return (
//     <>
//       <Banner pageTitle="Careers" bannerClass="contact-us" />
//       <section className="about-section style-four py-120 rpt-55 rpb-0">
//         <div className="container">
//           <div className="row g-0align-items-center">
//             <div className="col-lg-6">
//               <Fade left delay={100}>
//                 <div className="about-four-images text-center">
//                   <div className="row">
//                     <div className="col-6">
//                       <img decoding="async" className="hover-img w-100" src="https://wordpress.iqonic.design/logik/wp-content/uploads/2021/03/Rectangle-41s.png" alt="" />
//                       <img className="rounded mt-4" src="/images/news/12.svg" alt="About" />
//                     </div>
//                     <div className="col-6">
//                       <img className="rounded" src="/images/news/11.svg" alt="About" />
//                       <img decoding="async" className="hover-img w-100 mt-4" src="https://wordpress.iqonic.design/logik/wp-content/uploads/2021/03/Rectangle-41s.png" alt="" />
//                     </div>
//                   </div>
//                 </div>
//               </Fade>
//             </div>
//             <div className="col-lg-6">
//               <Fade right delay={100}>
//                 <div className="about-content pl-90 rpl-0 rpr-0 rmb-55">
//                   <div className="section-title mb-35 rpt-30">
//                     <span className="sub-title">{t('careers_title')}</span>
//                     <h2>{t('careers_subtitle')}</h2>
//                   </div>
//                   <div dangerouslySetInnerHTML={{ __html: t('careers_text') }}></div>
//                 </div>
//               </Fade>
//             </div>
//           </div>
//         </div>
//       </section>


//       <section className="py-120 rpy-50">
//         <div className="container">
//           <div className="row">
//             <div className="col-4 ">
//             </div>
//             <div className="col-8">
//               <div className="row g-0">
//                 {cariers.map((item, index) =>
//                   <div className="col-12 col-md-6 col-lg-6 d-flex p-1" key={index}>
//                     <div className="d-flex align-items-stretch">
//                       <Fade className="fadeInUp" delay={item.delay} >
//                         <div className={`bg-white p-3 rounded careers-card m-2 ${index % 2 === 0 ? 'mb-5' : 'mt-5'}`}>
//                           <h3 className="two-line-heading">{t(item.title)}</h3>
//                           <p>{t(item.text)}</p>
//                         </div>
//                       </Fade>
//                     </div>
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>


//       <section className="py-100">
//         <div className="container">
//           <div className="section-title text-center mb-35">
//             <span className="sub-title">Join the team</span>
//             <h2>Open positions</h2>
//           </div>
//           {JobsData.length !== 0 ? <Jobs /> : (
//             <>
//               <p className="text-center"><i>Trenutno nemamo otvorene pozicije, ali slobodno pošaljite svoju biografiju za buduće razmatranje.</i></p>
//               <CVForm />
//             </>
//           )}
//         </div>
//       </section>




      
//     </>
//   )
// }
// export default Careers