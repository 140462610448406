import React, { useState, } from "react";
import Slider from "react-slick";
import ColaborationData from "../data/ColaborationData.json";
import { useTranslation } from "react-i18next";

const Collaboration = () => {
  const { t } = useTranslation();
  const [rotate, setRotate] = useState(false);

  const rotateItem = (rotate, key) => {
    setRotate(rotate === false ? key : (rotate === key ? false : key))
    if (window.getSelection) { window.getSelection().removeAllRanges(); }
    else if (document.selection) { document.selection.empty(); }
  }

  return (
    <>
      {/* for desktop */}
      <section className="contact-two pb-130 pb-xl-0 pb-xxl-5 pt-50 rpt-50 rpb-50 d-none d-md-block">
        <div className="">
          <div className="section-title text-center mb-55 wow fadeInUp delay-0-2s">
            <span className="sub-title">{t('colaboration_subtitle')}</span>
            <h2>{t('colaboration_title')}</h2>
          </div>
          <div className="row g-0 d-flex justify-content-center px-10 pading-flip-card">
            {ColaborationData.map((item, key) => (
              <div className="col-12 col-md-3 col-xl p-2" key={key}>
                <div className={`flip-card mx-auto aspect-ratio-collaboration  ${rotate === key ? 'rotate' : ''}`} onMouseEnter={() => setRotate(key)} onMouseLeave={() => setRotate(false)}>
                  <div className="flip-card-inner">
                    <div className={`project-item wow fadeInUp delay-0-${(key + 1) * 2}s flip-card-front px-1`}>
                      <div className="image">
                        <div className="p-4 px-lg-5">
                          <img src={`/images/collaboration/${item.image}.png`} className=" d-none d-md-block image-size-collaboration" alt="Project" />
                        </div>
                        <div className="">
                          <img src={`/images/collaboration/${item.image}.png`} className="w-50 d-md-none " alt="Project" />
                        </div>
                      </div>
                      <div className="content text-white">
                        <h5>{t(item.title)}</h5>
                      </div>
                    </div>
                    <div className="flip-card-back text-white">
                      <p dangerouslySetInnerHTML={{ __html: t(item.text) }} className="content d-flex align-items-center flip-card-back-font"></p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>



      {/* for mobile */}
      <section className="py-130 rpy-50 rel z-1 bg-white d-block d-md-none">
        <div className="">
          <div className="row g-0 justify-content-center">
            <div className="col-xl-8">
              <div className="section-title text-center text-white mb-45 wow fadeInUp delay-0-2s">
                <span className="sub-title mb-1 text-dark">{t('colaboration_subtitle')}</span>
                <h2 className="text-dark">{t('colaboration_title')}</h2>
              </div>
            </div>
          </div>
          <div className="slider-container-colaboration big-slider" >
            <Slider
              speed={1300}
              // className={`${isHovered ? 'd-none' : ''}`}
              swipeToSlide={false}
              arrows={false}
              centerMode={true}
              focusOnSelect={true}
              slidesToShow={3}
              responsive={[
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1
                  }
                }
              ]}
            >
              {ColaborationData.map((item, key) => (
                <div className="col-12 col-md-4 col-lg-3 col-xl" key={item.id}>
                  <div className={`flip-card ${rotate === key ? 'rotate' : ''}`} onClick={() => rotateItem(rotate, key)}>
                    <div className={`flip-card-inner`}>
                      <div className={`project-item wow fadeInUp delay-0-${(key + 1) * 2}s flip-card-front`}>
                        <div className="image pb-3 d-flex justify-content-center align-items-center text-center">
                          <img src={`/images/collaboration/${item.image}.png`} className="w-50 d-md-none" alt="Project" />
                        </div>
                        <div className="content text-white">
                          <h3>{t(item.title)}</h3>
                        </div>
                      </div>
                      <div className="flip-card-back text-white px-3">
                        <p dangerouslySetInnerHTML={{ __html: t(item.text) }} className="content d-flex align-items-center justify-content-center"></p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
    </>
  )
}
export default Collaboration