import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Animation from "./Animation"
import { useEffect, useRef, useState } from "react"
import Slider from "react-slick";
import CustomersData from "../data/CustomersData.json"

const SliderHome = () => {
  const videos = {
    desktop: "/videos/AppSterWeb.mp4",
    mobile: "/videos/AppSterMob.mp4"
  }
  const getDeviceVideo = navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i) ? videos.mobile : videos.desktop;

  const { t } = useTranslation();
  const ref = useRef();
  const [slideIndex, setSlideIndex] = useState(0);

  const sliderImages = ["admiral", "boda", "md-shop", "novomatic-tournament", "pin-bet"]

  useEffect(() => {
    const slideItem = ref.current.querySelector(`.slider-area .slider-item img.slide${slideIndex}`);
    const imgWidth = slideItem.offsetWidth;
    slideItem.classList.remove('hidden')
    slideItem.style.transform = 'translateX(0%)';
    slideItem.style.transform = `translateX(-${(slideIndex * imgWidth)})`;
    slideItem.style.opacity = 1;

    const animationInterval = setInterval(() => {
      setSlideIndex(prev => prev === sliderImages.length - 1 ? 0 : prev + 1)
      slideItem.style.transform = 'translateX(-80%)';
      slideItem.style.opacity = 0;
      slideItem.classList.add('hidden')
      setTimeout(() => {
        slideItem.style.transform = 'translateX(200%)';
      }, 1000);
    }, 5000);

    return () => {
      clearInterval(animationInterval);
    };
  }, [slideIndex, sliderImages.length])

  return (
    <div ref={ref}>
      <section className="slider-area bgs-cover container-home">
        <div className="" style={{ width: "100dvw", height: "100dvh" }}>
          <Animation />
          {/* <video
            className="vw-100"
            src={getDeviceVideo}
            type="video/mp4"
            playsInline
            autoPlay
            muted
            loop
          /> */}
        </div>
        <div ref={ref}>
          <div className="row container container-slider-home align-items-center ps-md-0 pe-md-0" style={{ paddingLeft: "37px" }}>
            <div className="col-12 col-md-6 slider-item order-2 order-md-1 text-center text-md-start animation-left">
              <div className="slide-text-content text-white rmb-30">
                <div className="d-none d-md-block">
                  <span className="title">{t('home_slider_title_one')}</span>
                  <span className="title font_slider_home">{t('home_slider_title_two')}</span>
                </div>
                <h3 className="mt-40 mb-80 rmt-40 rmb-40 max-width-home-slider">{t('home_slider_text')}</h3>
                <Link to="/contact" className="text-decoration-none style-two call-to-action-button text-center">{t('contact_us')}</Link>
              </div>
            </div>
            <div className="text-center text-md-start animation-left rpt-slider-home d-block d-md-none">
              <div className="slide-text-content text-white ">
                <span className="title title-home-slider">{t('home_slider_title_one')}</span>
                <span className="title font_slider_home">{t('home_slider_title_two')}</span>
              </div>
            </div>
            <div className="col-12 col-md-6 order-1 order-md-2 height-slider-home" style={{ height: "431px" }}>
              <div className="slider-item home-slider">
                <div className="slide-content text-white mx-auto d-flex mt-3">
                  {sliderImages.map((item, key) => (
                    <img src={`/images/slider/${item}.png`} className={`mx-auto w-100 slide${key}`} style={{ position: "absolute" }} alt="" key={key}></img>
                  ))}
                  

                  {/* <div className="" style={{ position: "absolute" }}>
                    <Slider
                      speed={2300}
                      swipeToSlide={false}
                      arrows={false}
                      focusOnSelect={true}
                      slidesToShow={1}
                      autoplay={true}
                      autoplaySpeed={3}
                      responsive={[
                      ]}
                    >
                      {sliderImages.map((item, key) => (

                        <img src={`/images/slider/${item}.png`} alt="Project" className="" />

                      ))}
                    </Slider>
                  </div> */}


                </div>
              </div>




              {/* <div className="">
            <Slider
                    speed={2300}
                    swipeToSlide={false}
                    arrows={false}
                    focusOnSelect={true}
                    slidesToShow={1}
                    autoplay={true}
                    autoplaySpeed={3}
                  >
                    {sliderImages.map((item, key) => (
                      <div className="col-auto d-flex justify-content-center" key={item.id}>
                        <div className="d-flex align-items-center text-center">
                          <img src={`/images/slider/${item}.png`} alt="Project"/>
                        </div>
                      </div>
                    ))}
                  </Slider>
            </div> */}




            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
export default SliderHome