import { useEffect, useState } from "react";
import useToggle from "../hooks/useToggle";
import Drawer from "./Drawer"
import { Link } from "react-router-dom"

const MobileHeader = () => {
  const [drawer, setValue] = useToggle(false);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 90) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Drawer drawer={drawer} action={setValue.toggle} />
      <div className={`home-nine mobile-header d-block d-lg-none ${isSticky ? 'fixed-header' : ''}`}>
        <div className="d-flex justify-content-between align-items-center p-2 h-100">
          <div className="logo-area">
            <Link to="/#"><img className="mobile-logo-size" src={isSticky ? `/images/logo/logo-blue.png` : `/images/logo/logo-white.png`} alt="Logo" title="Logo" /></Link>
          </div>
          <div className="button-area">
            {isSticky ? <span className="text-primary h1" onClick={setValue.toggle}><i className="bi bi-list"></i></span> : <span className="text-white h1" onClick={setValue.toggle}><i className="bi bi-list"></i></span>}
          </div>
        </div>
      </div>
    </>
  )
}
export default MobileHeader