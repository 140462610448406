import { Link } from "react-router-dom"
import ContactForm from "../components/ContactForm/ContactForm"
import { useTranslation } from "react-i18next"
import { Fade } from "react-reveal";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="page-banner bgs-cover overlay pt-50" style={{ backgroundImage: "url(/images/cover-images/contact.png)" }}>
        <div className="container">
          <div className="banner-inner animation-up">
              <h1 className="page-title text-center text-md-start rmt-100">{t('contact_us')}</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                {/* <li className="breadcrumb-item"><Link to="/#">Home</Link></li> */}
                {/* <li className="breadcrumb-item active">{pageLink ? pageLink : "404 Not Found"}</li> */}
              </ol>
            </nav>
          </div>
        </div>
      </section>
      {/* <Fade className="fadeInLeft" delay={400}> */}
        <section className="contact-page pt-50 pb-70 rpy-50 animation-up">
          <div className="container">
            <div className="contact-info-area mb-50">
              <div className="contact-info-item">
                <i className="bi bi-geo-alt"></i>
                <p>Mihaila Avramovića 52B 11000 {t('belgrade')}</p>
              </div>
              <div className="contact-info-item">
                <i className="bi bi-envelope"></i>
                <p>
                  <a href="mailto:appsteroffice@appstersolutions.com">appsteroffice@appstersolutions.com</a>
                  {/* <Link to="#">www.restly.net</Link> */}
                </p>
              </div>
              <div className="contact-info-item">
                <i className="bi bi-telephone"></i>
                <p>
                  <a href="tel:+381643050362">+381643050362</a>
                  {/* <a href="tel:+0234(456)9864">+0234 (456) 9864</a> */}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="contact-form-left bgs-cover h-100 text-center text-md-start" style={{ backgroundImage: "url(/images/contact/ContactForm.webp)" }}>
                  <h2>{t('leave_message')}</h2>
                </div>
              </div>
              <div className="col-lg-8 py-3 ps-3 ps-md-5 py-md-5">
                <ContactForm />
                {/* <div className="contact-form ml-40 rml-0 rmt-55">
                <h3 className="comment-title mb-35">Send A Message</h3>
                <p>Avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter</p>
                <ContactForm /> *
              </div> */}
              </div>
            </div>
          </div>
        </section>
      {/* </Fade> */}
      <section className="contact-page-map wow fadeInUp delay-0-2s">
        <div className="our-location">
          <iframe src="https://maps.google.com/maps?q=44.762078,20.461175&z=15&output=embed&cid=12815618111696581593" width="100%" height="600" frameborder="0" ></iframe>
        </div>
      </section>
    </>
  )
}
export default Contact