import Banner from "../components/Banner"
import AboutUs from "../components/AboutUs"
import { Fade } from "react-reveal"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import React, { useEffect, useState, } from "react";
import AboutUsData from "../data/AboutUsData.json";



const AboutUsPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Banner pageTitle="About us" bannerClass="contact-us" />
      <AboutUs />

      <section className="featured-section bgs-cover pt-50 rpt-50 pb-180 rpb-100">
        <div className="container">
          <div className="row d-flex justify-content-center">
            {AboutUsData.map((item, key) =>
              <div className="col-xl-4 col-md-6 align-self-stretch mb-4 mb-l-0" key={item.id}>
                <Fade delay={item.delay}>
                  <div className="flip-card-inner-about-us style-two pb-5 px-md-4 pb-md-5 text-white text-center mb-3 mb-xl-0">
                    <div className="my-4">
                      <img src={`/images/about/${item.image2}.png`} alt="" className="icon-about-us px-1" />
                    </div>
                    <p dangerouslySetInnerHTML={{ __html: t(item.text2) }}></p>
                  </div>
                </Fade>
              </div>
            )}
          </div>
        </div>
      </section>

      <section className="work-progress-three text-white">
        <div className="container">
          <div className="logo-carousel-wrap style-two call-to-action-inner align-items-center rpx-5">
            <div className="row align-items-center animation-up mx-0 ms-md-3 me-md-3">
              <h2 className="col-12 col-md-9 text-center text-lg-start mb-0" dangerouslySetInnerHTML={{ __html: t('about-us-facts') }} ></h2>
              <div className="col-12 col-md-3 d-flex justify-content-center justify-content-md-end">
                <Link to="/contact" className="text-decoration-none style-two call-to-action-button text-center rmt-45">{t('contact_us')}</Link>
              </div>
            </div>
          </div>
          <div className="work-progress-inner-three ">
            <div className="row g-0 d-flex justify-content-center">
              {AboutUsData.map((item, key) =>
                <div className="col-12 col-md-6 col-lg-4 d-flex" key={key}>
                  <Fade className="fadeInUp" delay={item.delay} >
                    <div className="text-center style-two pb-5 px-md-3 pb-md-5">
                      <h3>{t(item.title)}</h3>
                      <div className="my-4">
                        <img src={`/images/about/${item.image}.png`} alt="" className="icon-about-us px-1" />
                      </div>
                      <p dangerouslySetInnerHTML={{ __html: t(item.text) }}></p>
                    </div>
                  </Fade>
                </div>
              )}
            </div>
          </div>
        </div>
        <img src="/images/background/progress.png" alt="" className="image height-bg"></img>
      </section>
    </>
  )
}
export default AboutUsPage

