import { useTranslation } from "react-i18next"
import Banner from "../components/Banner"
import Collaboration from "../components/Collaboration"
import ContactFormContainer from "../components/ContactForm/ContactFormContainer"
import ServicesData from "../data/ServiceData.json";
import Technologies from "../components/Technologies"
import { Fade } from "react-reveal"

const Service = () => {
  const { t } = useTranslation()
  return (
    <>
      <Banner pageTitle={t('service')} bannerClass="contact-us" />
      <section className="services-three text-white pt-50 rpt-50 pb-70 rpb-50 bg-dark-bule-two">
        <div className="container">
          <div className="section-title-with-btn mb-55 d-flex justify-content-center align-items-center">
            <div className="section-title text-center animation-up">
              <span className="sub-title text-center">{t('our_service_title')}</span>
              <h2>{t('our_service_subtitle')}</h2>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            {ServicesData.map((item, key) =>
              <div className="col-lg-4 col-sm-6 " key={key}>
                <Fade className="fadeInUp" delay={item.delay}>
                  <div className="service-three-item text-center">
                    <div className="d-flex justify-content-center align-items-center">
                      <img src={`/images/service/${item.image}`} alt="News" />
                    </div>
                    <h4 className="font-bold">{t(item.title)}</h4>
                    <p>{t(item.text)}</p>
                  </div>
                </Fade>
              </div>
            )}
          </div>
        </div>
      </section>
      <Collaboration />
      <Technologies />
      <div className="service-contact">
        <ContactFormContainer />
      </div>
    </>
  )
}
export default Service