import ContactForm from "./ContactForm";
import ContactFormContactUs from "./ContactFormContatUs";
import { useLocation } from "react-router-dom";

const ContactFormContainer = () => {
  const location = useLocation()
  return (
    <>
      <section className="contact-two">
        <div className="container">
          <div className="row g-0">
            <div className="contact-section-inner br-5 bg-white px-3 px-xl-5">
              <div className="row">
                <div className={`col-lg-${location.pathname !== "/contact" ? "7" : "12"}`}>
                  <ContactForm />
                </div>
                {location.pathname !== "/contact" && (
                  <div className="col-lg-5 mt-5 mt-lg-0">
                  <ContactFormContactUs />
                </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default ContactFormContainer;