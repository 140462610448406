import { useTranslation } from "react-i18next"
import SingelServiceService from "./SingleServiceService"
import ServicesData from "../../data/ServiceData.json";
import { Fade } from "react-reveal";
import Tilt from 'react-parallax-tilt';
import { useState } from "react";

const Services = () => {
  const { t } = useTranslation();
  const [scale] = useState(1.04);

  const [projectManagement, setProjectManagement] = useState(true);
  const [techStack, setTechStack] = useState(false);
  const [customBuild, setCustomBuild] = useState(false);
  const [transitionDivPM, settransitionDivPM] = useState(true);
  const [transitionDivTS, settransitionDivTS] = useState(false);
  const [transitionDivCB, settransitionDivCB] = useState(false);
  // const [rotatePM, setRotatePM] = useState(0);
  // const [rotateTS, setRotateTS] = useState(0);
  // const [rotateCB, setRotateCB] = useState(0);

  const showpm = () => {
    settransitionDivPM(!transitionDivPM);
    setTechStack(false);
    setCustomBuild(false);
    // setRotatePM(rotatePM === 0 ? 180 : 0);
    // setRotateTS(0);
    // setRotateCB(0);
    setTimeout(() => {
      setProjectManagement(!projectManagement);
    }, projectManagement ? 1 : 1000)
    transitionDivCB && settransitionDivCB(false);
    transitionDivTS && settransitionDivTS(false);
  };

  const showts = () => {
    settransitionDivTS(!transitionDivTS);
    setProjectManagement(false);
    setCustomBuild(false);
    // setRotatePM(0);
    // setRotateTS(rotateTS === 0 ? 180 : 0);
    // setRotateCB(0);
    setTimeout(() => {
      setTechStack(!techStack);
    }, techStack ? 1 : 1000)
    transitionDivPM && settransitionDivPM(false);
    transitionDivCB && settransitionDivCB(false);
  };

  const showcb = () => {
    settransitionDivCB(!transitionDivCB);
    setProjectManagement(false);
    setTechStack(false);
    // setRotatePM(0);
    // setRotateTS(0);
    // setRotateCB(rotateCB === 0 ? 180 : 0);
    setTimeout(() => {
      setCustomBuild(!customBuild);
    }, customBuild ? 1 : 1000)
    transitionDivPM && settransitionDivPM(false);
    transitionDivTS && settransitionDivTS(false);
  };

  return (
    <>
      <section className="services-three pt-40 pb-70 rpy-50 bg-white">
        <div className="container">
          <div className="section-title-with-btn mb-20 d-flex justify-content-center align-items-center animation-up">
            <div className="section-title text-center">
              <span className="sub-title text-center">{t('services_subtitle')}</span>
              <h2 className="font-blue">{t('services_title')}</h2>
              <div className="col-12 col-md-8 text-center pt-3 pt-md-5 mx-auto">
                <p className="font-blue" dangerouslySetInnerHTML={{ __html: t('home_services_text') }}></p>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            {ServicesData.map((item, key) =>
              <div className="col-lg-4 col-sm-6 px-md-5" key={key}>
                <SingelServiceService data={item} />
              </div>
            )}
          </div>
        </div>
        <div className="container">
          <div className="">
            <div className="row">
              <div className="col-lg-5 row g-0 align-items-center d-none d-lg-flex">
                <Fade left delay={300}>
                  <div className="about-two-image ps-5 pe-5">
                    <Tilt scale={scale} transitionSpeed={4000}>
                      <img src="/images/service/about_us.png" alt="About" />
                    </Tilt>
                  </div>
                </Fade>
              </div>
              <div className="col-lg-7 align-self-end pt-30">
                <Fade right delay={300}>
                  <div className="about-content pl-20 rpt-20 rpx-15 text-center text-md-start">
                    <div className="section-title mb-35">
                      <span className="sub-title">{t('home_about_title')}</span>
                      <h2 className="font-blue">{t('home_about_subtitle')}</h2>
                    </div>
                    <p>{t('home_about_text')}</p>
                  <div className="col-lg-5 row g-0 align-items-center d-block d-lg-none">
                    <Fade left delay={300}>
                      <div className="about-two-image py-0 py-md-5">
                        <Tilt scale={scale} transitionSpeed={4000}>
                          <img src="/images/service/about_us.png" alt="About" />
                        </Tilt>
                      </div>
                    </Fade>
                  </div>
                    <div className="ps-0 rpt-50">
                      <div className={` ${!transitionDivPM ? "small-height" : "big-height"} `} onClick={showpm} style={{ transition: "1s ease-in-out" }}>
                      <div className={`p-2 px-4 d-flex justify-content-between cursor-pointer ${!transitionDivPM ? "small-height" : "big-height"}`} onClick={showpm} style={{ transition: "1s ease-in-out"}}>
                        
                        <div>
                          <p className="col-12 align-items-center text-center text-md-start font-bold">{t('our_mision_title')}</p>
                          <div className="col-12 pb-2" style={{ transition: "1s ease-out", opacity: projectManagement ? "1" : "0", }}>
                          <p dangerouslySetInnerHTML={{ __html: t('our_mision_text')}} ></p>
                          </div>
                        </div>
                        {/* <div className=" col-1 text-center">
                          <img className="transition icon-service-size" src="/images/service/arrows.png" alt="arrows" style={{ transform: `rotate(${rotatePM}deg)` }} />
                        </div> */}
                      </div>
                      </div>
                      


                      <div className={` ${!transitionDivTS ? "small-height" : "big-height"} `} onClick={showts} style={{ transition: "1s ease-in-out" }}>
                      <div className={`p-2 px-4 d-flex justify-content-between cursor-pointer ${!transitionDivTS ? "small-height" : "big-height"}`} onClick={showts} style={{ transition: "1s ease-in-out"}}>
                        <div>
                          <p className="col-12 align-items-center text-center text-md-start font-bold">{t('our_vision_title')}</p>
                          <div className="col-12 pb-2" style={{ transition: "1s ease-out", opacity: techStack ? "1" : "0", }}>
                          <p dangerouslySetInnerHTML={{ __html: t('our_vision_text')}} ></p>
                          </div>
                        </div>
                        {/* <div className="col-1 text-center">
                          <img className="transition icon-service-size" src="/images/service/arrows.png" alt="arrows" style={{ transform: `rotate(${rotateTS}deg)` }} />
                        </div> */}
                      </div>
                      </div>

                      <div className={` ${!transitionDivCB ? "small-height" : "big-height"} `} onClick={showcb} style={{ transition: "1s ease-in-out" }}>
                      <div className={`p-2 px-4 d-flex justify-content-between cursor-pointer ${!transitionDivCB ? "small-height" : "big-height"}`} onClick={showcb} style={{ transition: "1s ease-in-out"}}>
                        <div>
                          <p className="col-12 align-items-center text-center text-md-start font-bold ">{t('our_culture_title')}</p>
                          <div className="col-12 pb-2" style={{ transition: "1s ease-out", opacity: customBuild ? "1" : "0", }}>
                          <p dangerouslySetInnerHTML={{ __html: t('our_culture_text')}} ></p>
                          </div>
                        </div>
                        {/* <div className="col-1 text-center">
                          <img className="transition icon-service-size" src="/images/service/arrows.png" alt="arrows" style={{ transform: `rotate(${rotateCB}deg)` }} />
                        </div> */}
                      </div>
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
        <div className="project-shapes d-none d-md-block">
          <img
            className="shape one-service"
           src="/images/shape-left-service.png"
             alt="shape"
           />
          <img
            className="shape two-service d-none d-md-block"
             src="/images/shape-right-service.png"
            alt="shape"
          />
         </div>
      </section>
    </>
  )
}
export default Services