import { useTranslation } from "react-i18next"
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation()
  const location = useLocation();

  const footerPadding = (location.pathname === '/' || location.pathname === '/service' || location.pathname === '/moments') ? '110px' : '0px';

  return (
    <footer className="main-footer footer-two bgs-cover text-white pt-150 rpt-115" style={{ backgroundImage: "url(/images/footer-bg-map.png)", paddingTop: footerPadding }}>
      <div className="container">
        {/* <FooterWidgetV1 /> */}
        <div className={`footer-widget-area pt-50`}>
          <div className="row">
            <div className="col-12 col-lg-3">
              <div className="footer-widget about-widget">
                <div className="footer-logo mb-35">
                  <Link to="/#"><img className="logo-footer" src="/images/logo/logo-white.png" alt="Logo" /></Link>
                </div>

              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div className="footer-widget link-widget ml-20 rml-0">
                <h4 className="footer-title">{t('page_links')}</h4>
                <ul className="list-style-two pl-5">
                  <li><Link to="/#">{t('home')}</Link></li>
                  <li><Link to="/about-us#">{t('aboutus')}</Link></li>
                  <li><Link to="/service#">{t('service')}</Link></li>
                  <li><Link to="/product#">{t('product')}</Link></li>
                  <li><Link to="/careers#">{t('careers')}</Link></li>
                  <li><Link to="/contact#">{t('contact')}</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="footer-widget link-widget ml-20 rml-0">
                <h4 className="footer-title">{t('explore')}</h4>
                <ul className="list-style-two pl-5">
                  <li><Link to="/moments">{t('our_works_link')}</Link></li>
                  {/* <li><Link to="/">{t('news_link')}</Link></li> */}
                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="footer-widget newsletter-widget">
                <ul className="contact-info mt-20 pl-5">
                  <li><i className="bi bi-geo-alt-fill"></i><span>Mihaila Avramovića 52b <br />{t('belgrade')}</span></li>
                </ul>      
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer