
// import AboutUsSection from "../components/AboutUsSection"
// import CallToAction from "../components/CallToAction"
// import { useTranslation } from "react-i18next";
import Collaboration from "../components/Collaboration"
import SliderHome from "../components/SliderHome"
import Services from "../components/Services/Services"
// import Technology from "../components/Technology/Technology"
import News from "../components/News"
import ContactFormContainer from "../components/ContactForm/ContactFormContainer"
import ProjectSlider from "../components/Slider/ProjectSlider"
import Technologies from "../components/Technologies";
import { useEffect } from "react"
// import ProjectsList from "../components/ProjectsList";

const Home = (props) => {
  useEffect(() => {
    setTimeout(()=>{
      if (props.moments) document.getElementById('topOfSection').scrollIntoView({ behavior: "smooth" });
    }, 1200)
  }, [])
  // const { t } = useTranslation();
  return (
    <>
      <SliderHome />
      <Services/>
      {/* <Technology /> */}
      <ProjectSlider />
      <Collaboration />
      <Technologies />
      {/* <News /> */}
      <div className="home-contact">
        <ContactFormContainer />
      </div>
    </>
  )
}
export default Home