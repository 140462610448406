import { useTranslation } from "react-i18next"
import Banner from "../components/Banner"
// import { Fade } from "react-reveal"
import { Link } from "react-router-dom"
import CustomersData from "../data/CustomersData.json"
import Slider from "react-slick";
import Home from "./Home"

const Service = () => {
  const { t } = useTranslation()
  return (
    <>
      <Banner pageTitle={t('product')} bannerClass="contact-us" />
      <section className="rel z-1">
        <div className="container">
          <div className="pt-50 rpt-50 px-xl-5 ">
            <div className="row px-xl-5 ">
              <div className="col-lg-8 animation-left pe-md-5">
                <div className="about-content py-75 rpt-0 rpb-20 pl-20 rpx-0 text-center text-md-start">
                  <div className="section-title mb-25">
                    <span className="sub-title">{t('product_title')}</span>
                    <h2 className="mb-5">{t('our_service_subtitle')}</h2>
                    <p className="font-bold">{t('product_text_bold')}</p>
                  </div>
                  <p className="text-justify">{t('product_text')}</p>

                  <div className="section-title mb-25 mt-40">
                    <p className="font-bold">{t('product_text_bold_2')}</p>
                  </div>
                  <p className="text-justify">{t('product_text_2')}<Link to="/moments">{t('product_link')}</Link>{t('product_text_3')}</p>
                  <div className="section-title mb-25 mt-40">
                    <span className="sub-title">{t('our_customers_title')}</span>
                    <p className="text-justify mt-25">{t('our_customers_text')}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 row g-0 align-items-end animation-right">
                <div className="about-two-image">
                  <img src="/images/product-page/mobile.png" alt="About" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="rel z-1">
          <div className="">            
            <div className="slider-container-colaboration big-slider-product bg-blue" >
              <Slider
                speed={2300}
                swipeToSlide={false}
                arrows={false}
                focusOnSelect={true}
                slidesToShow={9}
                autoplay={true}
                autoplaySpeed={3}
                responsive={[
                  {
                    breakpoint: 1200,
                    settings: {
                      slidesToShow: 4
                    }
                  },
                  {
                    breakpoint: 600,
                    settings: {
                      slidesToShow: 1
                    }
                  }
                ]}
              >
                {CustomersData.map((item, key) => (
                  <div className="col-12 col-md-4 col-lg-3 col-xl d-flex h-150px justify-content-center" key={item.id}>
                    <div className="d-flex align-items-center text-center">
                      <img src={`/images/customers/${item.icon}.svg`} alt="Project" className="custom-svg-size"/>
                    </div>                 
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </section>

        <div className="project-shapes d-none d-md-block">
          <img
            className="shape one-service"
            src="/images/shape-left-service.png"
            alt="shape"
          />
          <img
            className="shape two-service"
            src="/images/shape-right-service.png"
            alt="shape"
          />
        </div>
      </section>
    </>
  )
}
export default Service