import React, { useCallback } from "react";
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim";

function Preloader() {
  const particlesInit = useCallback(async engine => {
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async container => {
    // await console.log(container);
  }, []);
  return (
    <div className="preloader">
      <div id="particles-background" className="vertical-centered-box" />
      <div id="particles-foreground" className="vertical-centered-box" />
      <div className="vertical-centered-box">
        <div className="content">
          <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
              background: {
                color: {
                  value: "#0055b8",
                },
              },
              fpsLimit: 120,
              interactivity: {
                events: {
                  onClick: {
                    enable: true,
                    mode: "push",
                  },
                  onHover: {
                    enable: true,
                    mode: "repulse",
                  },
                  resize: true,
                },
                modes: {
                  push: {
                    quantity: 4,
                  },
                  repulse: {
                    distance: 200,
                    duration: 0.4,
                  },
                },
              },
              particles: {
                color: {
                  value: "#fff",
                },
                links: {
                  color: "#fff",
                  distance: 150,
                  enable: true,
                  opacity: 0.5,
                  width: 0.5,
                },
                move: {
                  direction: "none",
                  enable: true,
                  outModes: {
                    default: "bounce",
                  },
                  random: false,
                  speed: 1.5,
                  straight: false,
                },
                number: {
                  density: {
                    enable: true,
                    area: 1000,
                  },
                  value: 100,
                },
                opacity: {
                  value: 0.5,
                },
                shape: {
                  type: "circle",
                },
                size: {
                  value: { min: 1, max: 4 },
                },
              },
              detectRetina: true,
            }}
          />
          {/* <div className="test">
            <div className="loader-circle" />
            <div className="loader-line-mask">
              <div className="loader-line" />
            </div>
            <img src="/images/logo/appster-white.svg" alt=""></img>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Preloader;
