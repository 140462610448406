import React, { useEffect, useRef, useState } from 'react';
import projects from "../../data/ProjectsData.json"
// import { Fade } from 'react-reveal';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { Fade } from "react-reveal";

const ProjectSlider = () => {
  const ref = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const { t } = useTranslation();
  const carouselRef = useRef();
  const [projectIcons, setProjectIcons] = useState();
  const [currentSlideItem, setCurrentSlideItem] = useState({});
  const [nextSlideItem, setNextSlideItem] = useState({});
  const [previousSlideItem, setPreviousSlideItem] = useState({});
  const [transitionClass, setTransitionClass] = useState({});
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [nav3, setNav3] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);
  let sliderRef3 = useRef(null);

  const settings = {

    slidesToShow: 6,
    initialSlide: 1,
    infinite: true,
    arrows: false,
    swipeToSlide: true,
    focusOnSelect: true,
    speed: 1000,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 5,
          infinite: true
        }
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          infinite: true
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          infinite: true
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          infinite: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          infinite: true
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
          infinite: true,
        }
      }
    ]
  };

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
    setNav3(sliderRef3);
  }, []);

  const handleClick = (item) => {
    const element = ref2.current.querySelector('.image-top');
    const element2 = ref3.current.querySelector('.top-part');
    element.classList.add('techFadeInRight')
    element2.classList.add('techFadeInLeft')
    setTimeout(() => {
      element.classList.remove('techFadeInRight');
      element2.classList.remove('techFadeInLeft')
    }, 1000)
    document.getElementById('projectSlider').scrollIntoView({ behavior: "smooth", block: 'start' });
    let id = item.id;
    setNextSlideItem(item);
    setTransitionClass('trans-left');
    setCurrentSlideItem(item);
    setTimeout(() => {
      setPreviousSlideItem(id == 1 ? projects[9] : projects[id - 2]);
      setTransitionClass('');
    }, 1000)
  }

  useEffect(() => {
    const firstItem = projects[0];
    let newArray = projects.slice(1)
    newArray.push(firstItem);
    setProjectIcons(newArray);
    setCurrentSlideItem(firstItem);
    setPreviousSlideItem(projects[8])
  }, [])

  return (
    <>
      <div ref={carouselRef}>
        <section id='topOfSection' className='project-carousel pt-40 pb-70 rpt-50 rpb-85 rel z-1' ref={ref}>
          <section className='icon-carousel-section container'>
            <div className="section-title text-center mb-40 text-white">
              <span className="sub-title">{t('our_work_title')}</span>
              <h2 id="projectSlider" >{t('our_work_subtitle')}</h2>
            </div>

            <div className='d-none d-xl-block col-12 mb-5 text-center header-height' ref={ref2}>
              <img src={`/images/projects/${currentSlideItem['image-top']}.png`} className="image-top" alt="" />
            </div>
            <div className="d-none d-xl-flex justify-content-center">
              <div className='col-auto d-none d-md-flex align-items-end mb-5 pe-5 pe-xxl-4 justify-content-end me-3'>
                <img src={`/images/projects/${previousSlideItem.icon}.png`} alt='' className='' style={{ width: "100px", height: "auto" }} onClick={() => handleClick(previousSlideItem)}></img>
              </div>
              <div className='col-12 col-md-auto'>
                <div className='image-holder mx-auto' style={{ position: "relative", overflow: "hidden", borderRadius: "40px" }}>
                  <img src='/images/projects/vector.png' alt='' className='mobile-image' style={{ position: "absolute", top: 0, right: 0, height: "100%", zIndex: 2 }}></img>
                  <img src={`/images/projects/${currentSlideItem.image}.png`} alt='' className={`${transitionClass} mobile-image`} style={{ position: "absolute", top: 0, right: 0, height: "100%" }}></img>
                  <img src={`/images/projects/${nextSlideItem.image}.png`} alt='' className={`${transitionClass} mobile-image`} style={{ position: "absolute", top: 0, right: "-100%", height: "100%", zIndex: 1 }}></img>
                </div>
              </div>
              <div className='col-9' ref={ref3}>
                <div className='row top-part text-white ms-4'>
                  <div className='row no-gutters'>
                    <img src={`/images/projects/${currentSlideItem.icon}.png`} alt='' className='col-auto m-3 p-0' style={{ width: "60px", height: "60px" }}></img>
                    <h2 className='col-auto title pt-4'>
                      {t((currentSlideItem || {}).title)}
                    </h2>
                  </div>
                  <div className="pb-0 ms-1 " style={{ maxWidth: "600px" }}>{t((currentSlideItem || {}).text)}</div>
                </div>
                <div className='slider-container'>
                  <Slider  {...settings}>
                    {(projectIcons || []).map((item, key) => (
                      <div key={key}>
                        <img src={`/images/projects/${item.icon}.png`} alt='' onClick={() => handleClick(item)} className='m-2 m-md-0 trans-slider' style={{ width: "100px", height: "auto", cursor: "pointer" }} key={key}></img>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
            <div className='d-block d-xl-none' style={{ position: "relative", height: "720px" }}>
              <div className='mobile-slider-container mx-auto'>
                <Slider speed={1500} pauseOnHover={true}  slidesToShow={1} arrows={false} asNavFor={nav2} ref={slider => (sliderRef3 = slider)} >
                  {(projectIcons || []).map((item, key) => (
                    <div key={key} style={{ position: "absolute" }}>
                      <img src={`/images/projects/${item.image}.png`} alt='' className='' key={key}></img>
                    </div>
                  ))}
                </Slider>
                <img src='/images/projects/vector.png' alt='' style={{ position: "absolute", top: "-3px", left: "0px", height: "100%", pointerEvents: "none", zIndex: 2, width: "100%" }}></img>
              </div>
              <div className='slider-mobile-icons'>
                <Slider centerPadding={"0px"} speed={1500} pauseOnHover={true} autoplay={true} slidesToShow={5} arrows={false} asNavFor={nav3} ref={slider => (sliderRef1 = slider)} swipeToSlide={true} focusOnSelect={true} centerMode={true}>
                  {(projectIcons || []).map((item, key) => (
                    <div key={key}>
                      <img src={`/images/projects/${item.icon}.png`} alt='' className='mx-auto' style={{ width: "60px", height: "60px" }}></img>
                    </div>
                  ))}
                </Slider>
              </div>
              <div className="mobile-slider-projects mt-sm-4">
                <Slider speed={1500} infinite={true} pauseOnHover={true} asNavFor={nav1} ref={slider => (sliderRef2 = slider)} slidesToShow={1} arrows={false} swipeToSlide={true}>
                  {(projectIcons || []).map((item, key) => (
                    <div key={key} >
                      <h2 className='col title rpt-35 text-white text-center'>
                        {t((item || {}).title)}
                      </h2>
                      <div className="pb-0 mt-2 text-white text-center" dangerouslySetInnerHTML={{ __html: t((item || {}).text) }}></div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </section>
        </section>
      </div>
    </>
  )
}
export default ProjectSlider;