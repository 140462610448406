import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

const SingelServiceService = ({ data }) => {
  const { t } = useTranslation()
  return (
    <>
      <Fade className="fadeInUp" delay={data.delay}>
        <div className="service-three-item text-center">
          <div className="d-flex justify-content-center align-items-center">
              <img src={`/images/service/${data.image}`} alt="News" />
          </div>
          <h4 className="font-bold font-blue ">{t(data.title)}</h4>
          <p className="font-blue ">{t(data.text)}</p>
        </div>
      </Fade>
    </>
  )
}
export default SingelServiceService;