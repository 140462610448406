import { useTranslation } from "react-i18next"
import { Fade } from "react-reveal"

const ContactFormContactUs = () => {
  const { t } = useTranslation();

  return (
    <Fade className="fadeInRight" delay={300}>
      <div className="contact-image-number style-two bgs-cover overlay" style={{ backgroundImage: "url(/images/contact/ContactForm.webp)" }}>
        <div className="contact-informations text-white">
          {/* <h3>Dont hesitate to contact us</h3> */}
          <ul className={`${window.innerWidth > 767 ? "contact-info" : ""} ps-0 text-center text-md-start`}>
            <li className="my-35">
              <i className="bi bi-telephone-fill"></i>
              <div className="content">
                <span>{t('call-us')}</span>
                <h6><a href="tel:+381643050362">+381 64 30 50 362</a></h6>
              </div>
            </li>
            <li className="my-35">
              <i className="bi bi-envelope-fill"></i>
              <div className="content">
                <span>{t('write-to-us')}</span>
                <h6><a href="mailto:appsteroffice@appstersolutions.com">appsteroffice@appstersolutions.com</a></h6>
              </div>
            </li>
            <li className="my-35"> 
              <i className="bi bi-clock-fill"></i>
              <div className="content">
                <span>{t('working_hours')}</span>
                <h6>{t('working_days')} 9:00 - 17:00</h6>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Fade>
  )
}
export default ContactFormContactUs