import { useTranslation } from "react-i18next"
import Banner from "../components/Banner";
import { useParams } from "react-router-dom";
import posititions from "../data/openedPositionsData.json"
import JobApplyForm from "../components/JobApplyForm";

const JobApply = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const position = posititions.find(item => item.id == id)

  return (
    <>
      {/* <Banner pageTitle="Job Applay" bannerClass="job-applay" /> */}
      <Banner pageTitle={t('careers')} bannerClass="contact-us" />
      <section className="about-area-five py-130 rpt-100 rpb-65 rel z-1">
        <div className="container">
          <div className="section-title mb-60 rmb-40 text-center">
            <span className="sub-title mb-15">{t('be_part_of_team')}</span>
            <h2>{t('apply_for')} {position && position.title}</h2>
          </div>
          <div dangerouslySetInnerHTML={{ __html: t(position.description)}} className="mb-5 job-description"></div>
          <div dangerouslySetInnerHTML={{ __html: t('our_offer')}} className="mb-5 job-description"></div>
          <JobApplyForm/>
        </div>
      </section>
    </>
  )
}
export default JobApply