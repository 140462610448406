// import { Tab } from "react-bootstrap"
import { Tab } from "react-bootstrap"
import { Nav } from "react-bootstrap/esm"
import technologies from "../data/TechnologyData.json"
import { useTranslation } from "react-i18next"
import { useEffect, useRef, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"

const Technologies = () => {
  const { t } = useTranslation();
  const [selectedTechnology, setSelectedTechnology] = useState(technologies[0]);
  const [counter, setCounter] = useState(true);
  const ref = useRef();
  const responsiveSwiper = {
    1998: {
      width: 1998,
      slidesPerView: 11
    },
    1000: {
      width: 1000,
      slidesPerView: 9
    },
    790: {
      width: 790,
      slidesPerView: 8
    },
    665: {
      width: 665,
      slidesPerView: 7
    },
    575: {
      width: 575,
      slidesPerView: 6
    }
  }

  useEffect(() => {
    const element = ref.current.querySelector('.why-choose-content');
    element.classList.remove('.techFadeInLeft')
    setTimeout(() => {
      element.classList.add('.techFadeInLeft')
    }, 500)
  }, [selectedTechnology])

  return (
    <section className="why-choose-us-area py-50 rpy-50 rel bg-blue">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8 animation-up">
            <div className="section-title text-center mb-65 rmb-30 text-white">
              <span className="sub-title">{t('technology_title')}</span>
              <h2>{t('technology_subtitle')}</h2>
            </div>
          </div>
        </div>
        <div className="technology-slider-mobile d-block d-xl-none">
          <div className="swiper-container">
            <Swiper
              spaceBetween={10}
              slidesPerView={5.7}
              breakpoints={responsiveSwiper}
            >
              {technologies.map((item, key) => (
                <SwiperSlide key={key}>
                  <div className="nav-item d-flex align-items-center justify-content-center" key={key} onClick={() => {setSelectedTechnology(item); setCounter(!counter)}} role="button">
                    <img src={`/images/technology/${item.icon}`} alt="" className="icon px-4"></img>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="row gap-90 align-items-center mt-3 text-center text-md-start" ref={ref}>
            <div className={`col-md-6 ${counter ? 'order-1 order-md-2 techFadeInLeft' : 'techFadeInRight'}`}>
              <div className="why-choose-image px-40">
                <img src={`/images/technology/${selectedTechnology.image}`} alt="" />
              </div>
            </div>
            <div className={`col-md-6 ${counter ? 'order-2 order-md-1 techFadeInRight' : 'techFadeInLeft'} `}>
              <div className={`why-choose-content rmt-40 text-white text-center`}>
                <h3 className="pb-2">{selectedTechnology.name}</h3>
                {selectedTechnology.id !== 7 && (
                  <p dangerouslySetInnerHTML={{ __html: t(selectedTechnology.text) }}></p>
                )}
                {selectedTechnology.id === 7 && (
                  <p dangerouslySetInnerHTML={{ __html: t('java_text') }}></p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="d-none d-xl-block">
          <Tab.Container defaultActiveKey={"wc-tap1"}>
            <div className="why-choose-tab">
              <Nav as={"ul"} className="nav nav-fill mb-80 rmb-50 wow fadeInUp delay-0-4s">
                {technologies.map((item, key) => (
                  <li className="nav-item" key={key}>
                    <Nav.Link
                      as="a"
                      eventKey={`wc-tap${key + 1}`}
                      className="nav-link"
                      data-bs-toggle="tab"
                    >
                      <img src={`/images/technology/${item.icon}`} alt="" className="icon"></img>
                    </Nav.Link>
                  </li>
                ))}
              </Nav>
              <Tab.Content className="tab-content mt-5">
                {technologies.map((item, key) => (
                  <Tab.Pane className="tab-pane fade" eventKey={`wc-tap${key + 1}`} key={key}>
                    <div className="row gap-90 align-items-center">
                      <div className={`col-lg-8 ${key % 2 === 0 ? 'order-1 order-md-2 pading-tech-left' : ''}  techFadeInLeft pading-tech-right`}>
                        <div className="why-choose-content text-white">
                          <h3 className="pb-4">{item.name}</h3>
                          {item.id !== 7 && (
                            <p dangerouslySetInnerHTML={{ __html: t(item.text) }}></p>
                          )}
                          {item.id === 7 && (
                            <p dangerouslySetInnerHTML={{ __html: t('java_text') }}></p>
                          )}
                        </div>
                      </div>
                      <div className={`col-lg-4 ${key % 2 === 0 ? 'order-2 order-md-1' : ''} techFadeInRight`}>
                        <div className="why-choose-image rmt-55 p-md-4">
                          <img src={`/images/technology/${item.image}`} alt="" />
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </div>
          </Tab.Container>
        </div>
      </div>
      <div className="project-shapes d-none d-md-block">
        <img
          className="shape shape-services one"
          src="/images/shape-left.png"
          alt="shape"
        />
        <img
          className="shape shape-services two"
          src="/images/shape-right.png"
          alt="shape"
        />
      </div>
    </section>
  )
}
export default Technologies