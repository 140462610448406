import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header/Header';
import './animate.css';
import './flaticon.css';
import { Route, Routes } from 'react-router-dom'
import Home from './pages/Home';
import Footer from './components/Footer';
import Contact from './pages/Contact';
import 'swiper/css/bundle';
import AboutUs from './pages/AboutUs';
import Product from './pages/Product';
import Service from './pages/Service';
import JobApply from './pages/JobApply';
import Careers from './pages/Careers';
import translationSR from "./locales/sr.json"
import translationEN from "./locales/en.json"
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import ScrollToTop from './scroll-to-top';

function App() {

  const resources = {
    sr: {
      translation: translationSR
    },
    eng: {
      translation: translationEN
    }
  };

  i18next
    .use(initReactI18next)
    .init({
      resources,
      lng: "eng",
      keySeparator: false, // we do not use keys in form messages.welcome
      interpolation: {
        escapeValue: false // react already safes from xss
      }
    });

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsLoading(false)
  //   }, 2000)
  // }, [])

  return (
    <ScrollToTop>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/moments" element={<Home moments={true}/>} />
        <Route path="*" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/product" element={<Product />} />
        <Route path="/service" element={<Service />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/job-apply/:id" element={<JobApply />} />
      </Routes>
      <Footer />
    </ScrollToTop>
  );
}

export default App;
