import { Fade } from "react-reveal"

const Banner = ({ pageTitle, bannerClass }) => {
  return (
    <section className={`page-banner bgs-cover pt-50 ${bannerClass}`}>
      <div className="container">
        <div className="text-center">
          <div className="banner-inner animation-up">
              <h1 className="page-title rmt-100">{pageTitle ? pageTitle : "404 Not Found"}</h1>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Banner