import { Fade } from "react-reveal"
import { useTranslation } from "react-i18next"
import React, { useState } from 'react';

const AboutUs = () => {
  const { t } = useTranslation()

  const [isVideoVisible, setVideoVisible] = useState(true);
  const playVideo = () => {
    document.querySelector('video').play();
    document.querySelector('video').setAttribute('controls', '')

    setVideoVisible(false);
    const videoElement = document.getElementById('novomaticVideo');
    if (videoElement) {
      videoElement.play();
    }

  }
  return (
    <>
      <section className="about-two bg-color pt-50 rpt-50 pb-50 rpb-10">
        <div className="container">
          <div className="about-wrap pt-20 rounded-3">
            <div className="row">
              <div className="col-12 col-lg-6 align-self-center animation-left">
                <div className="about-content pt-3 ps-3 pe-3 ps-md-4 pr-70 rpr-0 text-center text-md-start">
                  <div className="section-title mb-35">
                    <span className="sub-title">{t('about_title')}</span>
                    <h2>{t('about_subtitle')}</h2>
                  </div>
                  <p dangerouslySetInnerHTML={{ __html: t('about_text') }} ></p>
                </div>
              </div>
              <div className="col-12 col-lg-6 about-placeholder">
                <Fade right delay={300}>
                  <div className="px-3 p-md-3 mt-0 mt-md-3" style={{ top: "0%", right: "10%" }}>
                    <img src="/images/service/about-us-section-1.webp" alt="About" />
                  </div>
                </Fade>
              </div>
            </div>
          </div>
          <div className="col-12">
            <Fade left delay={300}>
              <div className="mt-120 rmt-50 position-relative">
                <video
                  id="novomaticVideo"
                  poster="/images/about/novomatic_image_video.webp"
                  className="h-100 w-100 rounded-3"
                  src={"https://www.novomatic.com/sites/default/files/video-thumbnails/2022-05/Novomatic_Update_2022_Delivery_V01_1.mp4"}
                  type="video/mp4"
                  playsInline
                />
                {isVideoVisible && (
                  <div className="position-absolute position_btn_video" onClick={playVideo}>
                    <i className="bi bi-play-circle pointer play_btn_style"></i>
                  </div>
                )}
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </>
  )
}
export default AboutUs