import { useState } from "react";
import { Link } from "react-router-dom";
import i18n from "i18next";
import { useTranslation } from "react-i18next"
import { Fade } from "react-reveal";

const Drawer = ({ drawer, action }) => {
  const { t } = useTranslation();
  const [lang, setLang] = useState("eng");

  const changeLanguage = () => {
    if (i18n.language === "sr") {
      i18n.changeLanguage("eng");
      setLang("eng");
    }
    else {
      i18n.changeLanguage("sr");
      setLang("sr");
    }
  }

  return (
    <>
    
      <div className={`off_canvars_overlay ${drawer ? "active" : ""}`} onClick={(e) => action(e)}></div>
      <div className="offcanvas_menu" onClick={(e) => action(e)}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className={`offcanvas_menu_wrapper ${drawer ? "active" : ""}`} >
                <Link to="/#" className="offcanvas-brand text-center mb-40 d-block" >
                  {/* <img src={logo} alt="logo" /> */}
                </Link>
                <div id="menu" className="text-left">
                  <div style={{ height: "27px", color: "#fff", cursor: "pointer", padding: "0px 0px", fontSize: "27px" }} className="d-flex justify-content-end"><i className="bi bi-x-circle"></i></div>
                  <ul className="offcanvas_main_menu">
                    <li className="menu-item-has-children active pb-4" style={{ borderBottom: "2px solid #666666" }}>
                      {/* <img src={`/images/language/${lang === "eng" ? "serbian.png" : "english.png"}`} alt="" onClick={changeLanguage} role="button"></img> */}
                      <img src={`/images/language/english.png`} className={`pe-3 ${lang === 'eng' ? 'opacity-50' : ''}`} alt="" onClick={() => changeLanguage('eng')} role="button"></img>
                      <img src={`/images/language/serbian.png`} className={lang === 'sr' ? 'opacity-50' : ''} alt="" onClick={() => changeLanguage('ser')} role="button"></img>
                    </li>
                    {drawer &&
                      <Fade className="fadeInDown" delay={100}>
                        <li className="menu-item-has-children active mt-2"  >
                          <Link to="/#">{t('home')}</Link>
                        </li>
                      </Fade>
                    }
                    {drawer &&
                      <Fade className="fadeInDown" delay={200}>
                        <li className="menu-item-has-children active" >
                          <Link to="/about-us#">{t('aboutus')}</Link>
                        </li>
                      </Fade>
                    }
                    {drawer &&
                      <Fade className="fadeInDown" delay={300}>
                        <li className="menu-item-has-children active" >
                          <Link to="/product#">{t('product')}</Link>
                        </li>
                      </Fade>
                    }
                    {drawer &&
                      <Fade className="fadeInDown" delay={400}>
                        <li className="menu-item-has-children active" >
                          <Link to="/service#">{t('service')}</Link>
                        </li>
                      </Fade>
                    }
                    {drawer &&
                      <Fade className="fadeInDown" delay={500}>
                        <li className="menu-item-has-children active" >
                          <Link to="/careers#">{t('careers')}</Link>
                        </li>
                      </Fade>
                    }
                    {drawer &&
                      <Fade className="fadeInDown" delay={600}>
                        <li className="menu-item-has-children active" >
                          <Link to="/contact#">{t('contact')}</Link>
                        </li>
                      </Fade>
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </>
  )
}
export default Drawer